import log from "loglevel";
import { Component, ErrorInfo, ReactNode } from "react";
import { ErrorComponent } from "../errorComponent";

type State = {
  retryCount: number;
  error?: Error;
  errorInfo?: ErrorInfo;
};

class ErrorBoundary extends Component<Record<string, unknown>, State> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = {
      retryCount: 0,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Catch errors in any components below and re-render with error message
    this.setState((prevState) => ({
      error,
      errorInfo,
      retryCount: prevState.retryCount + 1,
    }));
    log.error("Application crashed with the following error", `${error.name}: ${error.message}`, error.stack);
    // You can also log error messages to an error reporting service here
  }

  public render(): ReactNode {
    const { errorInfo, error } = this.state;
    if (errorInfo /*  && this.state.retryCount > 5 */) {
      // Error path
      return (
        <div style={{ width: "100%" }}>
          <ErrorComponent errorMessage="Impossibile avviare l'applicazione" />
          {/* <details style={{ whiteSpace: "pre-wrap" }}> */}
          {error && error.toString()}
          <br />
          {errorInfo.componentStack}
          {/* </details> */}
        </div>
      );
    }
    // Normally, just render children
    const { children } = this.props;
    return children;
  }
}

export const ErrorBoundaryComponent = ErrorBoundary;
