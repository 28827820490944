import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: "100%",
      minWidth: "100%",
      textAlign: "center",
    },
    cardContent: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    criticalLevel: {
      color: "red",
    },
    level: {
      fontWeight: 500,
      padding: "40px",
    },
    retryButton: {
      alignSelf: "center",
      width: "fit-content",
    },
    title: {
      padding: "10px",
    },
  }),
);
