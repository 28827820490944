import log from "loglevel";

interface IBarcodeOptionsKey {
  key: string;
  withControl: boolean;
  withAlt: boolean;
}

export interface IBarcodeOptions {
  startReadKey: IBarcodeOptionsKey;
  endReadKey: IBarcodeOptionsKey;
  regexFilter?: RegExp;
  /**
   * seconds
   */
  readTimeout?: number;
  onStatusChange?: (status: boolean) => void;
}

export class Barcode {
  private code = "";

  private readingCode = false;

  private readonly onBarcodeRead: (code: string) => void;

  private readonly options: IBarcodeOptions;

  private readingTimeout: NodeJS.Timeout | null;

  constructor(onBarcodeRead: (code: string) => void, options: IBarcodeOptions) {
    this.onBarcodeRead = onBarcodeRead;
    this.options = options;
    this.readingTimeout = null;
    this.getIsReading = this.getIsReading.bind(this);
    this.readKey = this.readKey.bind(this);
    this.onReadtimeout = this.onReadtimeout.bind(this);
    this.setIsReading = this.setIsReading.bind(this);
    this.setListener = this.setListener.bind(this);
    this.unsetListener = this.unsetListener.bind(this);
  }

  public setListener(): void {
    window.addEventListener("keydown", this.readKey, { passive: false });
  }

  public unsetListener(): void {
    window.removeEventListener("keydown", this.readKey);
  }

  public getIsReading(): boolean {
    return this.readingCode;
  }

  public readKey(e: KeyboardEvent): void {
    log.debug(this.options, e);
    const regex = this.options.regexFilter;
    if (
      e.key === this.options.startReadKey.key &&
      e.ctrlKey === this.options.startReadKey.withControl &&
      e.altKey === this.options.startReadKey.withAlt
    ) {
      this.code = "";
      this.setIsReading(true);
    } else if (
      e.key === this.options.endReadKey.key &&
      e.ctrlKey === this.options.endReadKey.withControl &&
      e.altKey === this.options.endReadKey.withAlt
    ) {
      log.debug("Barcode read", this.code);
      this.setIsReading(false);
      this.onBarcodeRead(this.code);
    } /* if (this.readingCode) */ else if (regex) {
      if (regex.test(e.key)) {
        log.debug(`key ${e.key} is valid for regex filter`);
        this.code += e.key;
      } else {
        log.debug(`key ${e.key} is NOT valid for regex filter`);
      }
    } else {
      this.code += e.key;
    } /* else {
      log.debug(`key ${e.key} discarded, barcode is not reading`);
    } */
  }

  private onReadtimeout() {
    log.debug("Barcode timeout", this.code);
    this.code = "";
    this.readingCode = false;
  }

  private setIsReading(isReading: boolean) {
    this.readingCode = isReading;
    log.debug(`barcode reader status is now ${isReading ? "READING" : "NOT READING"}`);
    if (isReading && this.options.readTimeout) {
      if (!this.readingTimeout) {
        this.readingTimeout = setTimeout(this.onReadtimeout, this.options.readTimeout * 1000);
      }
      this.code = "";
    } else if (!isReading && this.readingTimeout) {
      clearTimeout(this.readingTimeout);
    }

    if (this.options.onStatusChange) {
      this.options.onStatusChange(isReading);
    }
  }
}

export const simulateBarcode = (text: string): void => {
  window.dispatchEvent(new KeyboardEvent("keydown", { key: "<" }));
  for (let i = 0; i < text.length; i += 1) {
    window.dispatchEvent(new KeyboardEvent("keydown", { key: text.charAt(i) }));
  }
  window.dispatchEvent(new KeyboardEvent("keydown", { key: ">" }));
};
