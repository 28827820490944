import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() =>
  createStyles({
    card: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "center",
      minHeight: "100%",
      textAlign: "center",
    },
    cardContent: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    title: {
      marginBottom: "30px",
    },
  }),
);
