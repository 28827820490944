import { Card, CardContent, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { useStyles } from "./style/style";

type Props = {
  /**
   * The effective size of the loader, in px
   * @default 150
   */
  size?: number;
  /**
   * The message shown while the component is mouted
   */
  message?: JSX.Element;

  /**
   * The class name of the card component
   */
  cardClass?: string;

  /**
   * Root card elevation, 0 to hide
   * @default 1
   */
  elevation?: number;
};

export function Loading({ size, message, cardClass, elevation }: PropsWithChildren<Props>): JSX.Element {
  const classes = useStyles();
  const Component = elevation === 0 ? "div" : Card;

  return (
    <Component elevation={elevation || 1} className={clsx(classes.card, cardClass)}>
      <CardContent className={classes.cardContent}>
        {message}
        <CircularProgress size={size || 150} />
      </CardContent>
    </Component>
  );
}
